












import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OParticipantImport: () =>
      import(
        "@/components/organisms/event/participant/o-participant-import.vue"
      ),
  },
  setup() {
    return {};
  },
});
